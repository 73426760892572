import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { toCamel } from 'src/utils/object';
import { getTaskOptions as mock } from 'src/mocks/moveHours';
import { ITaskOptionsResponse } from '../types';

const GET_TASK_OPTIONS_URL_PATH = '/api/v2/adjustments/move-hours/projects/';
const GET_TASK_OPTIONS_MSW_STATUS_KEY = 'getTaskOptionsAPI';

const GET_TASK_OPTIONS_KEY = 'GET_TASK_OPTIONS_KEY';

const getTaskOptions = (filterId: number): Promise<ITaskOptionsResponse> =>
  getFetch({
    path: `${GET_TASK_OPTIONS_URL_PATH}${filterId}/tasks`,
    key: GET_TASK_OPTIONS_MSW_STATUS_KEY,
  });

export const useGetTaskOptions = (filterId: number) => {
  const { data, isError, ...restProps } = useQuery(
    [GET_TASK_OPTIONS_KEY, filterId],
    () => getTaskOptions(filterId),
    {
      retry: 0,
    },
  );

  // For testing, to be remove
  if (isError) {
    const { entities } = toCamel(mock) as any;
    return {
      taskOptions:
        entities?.map(({ properties }: any) => ({
          ...properties,
        })) || [],
      ...restProps,
    };
  }
  return {
    taskOptions:
      data?.entities?.map(({ properties }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };
};
