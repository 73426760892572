export default {
  Entities: [
    {
      Properties: {
        FilterViewId: '0',
        Name: 'MyActiveProjects',
        IsDefault: true,
        Created: '2024-9-19T17:15:33.008Z',
        LastModified: '2024-9-19T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectManager',
            Value: [
              {
                Key: 'Ang Kian Leong',
                Value: '1110',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          {
            Name: 'ProjectStatus',
            Value: [
              {
                Key: 'Active projects',
                Value: '-1',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          // {
          //   Name: 'DateRange',
          //   Value: {
          //     PeriodStartsAt: '2024-10-09T00:15:33.008Z',
          //     PeriodEndsAt: '2024-10-16T00:15:33.008Z',
          //   },
          //   //   Value: [
          //   //     "ONE_WEEK"
          //   // ],
          //   IsInclusive: true,
          //   FilterType: 'DateRange',
          // },
          // {
          //   Name: 'SearchText',
          //   Value: 'test',
          //   IsInclusive: true,
          //   FilterType: 'Textbox',
          // },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filter-view',
      Rel: 'self',
    },
  ],
};
