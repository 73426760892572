/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { IFilterCategory } from 'src/apis/types/filterListAPI';
import { filterMoveHoursFullResult } from 'src/mocks/filter';
import { getFetch } from 'src/utils/fetcher';
import { toCamel } from 'src/utils/object';

const FILTER_URL_PATH = (pageIdentifier: string) => `/api/v2/filters/list/${pageIdentifier}`;
export const FILTER_MSW_STATUS_KEY = 'filterAPI';

export const FILTER_KEY = 'FILTER';

export const useGetFilterAPI = (pageIdentifier: string) => {
  const filterFetch = (): Promise<IFilterCategory> =>
    getFetch({ path: FILTER_URL_PATH(pageIdentifier), key: FILTER_MSW_STATUS_KEY });

  const { data, ...restProps } = useQuery([`${FILTER_KEY}_${pageIdentifier}`], filterFetch, {
    enabled: !!pageIdentifier,
    retry: 0,
  });

  // For testing, to be remove
  const { entities } = toCamel(filterMoveHoursFullResult) as IFilterCategory;
  return {
    filterList:
      entities?.map(({ properties = {} }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };

  return {
    filterList:
      data?.entities?.map(({ properties = {} }) => ({
        ...properties,
      })) || [],
    ...restProps,
  };
};
