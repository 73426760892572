import { Card, CardContent, FormControl, Grid, Stack, Typography } from '@mui/material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { formatTime } from 'src/utils/time';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Dispatch, SetStateAction } from 'react';
import { IMoveHours, IProjectOptions } from 'src/apis/moveHoursAPI/types';
import { usePostRegistrationSplit } from 'src/apis/moveHoursAPI';
import { TaskAutoComplete } from './TaskAutoComplete';

interface IFormData {
  project: {
    id: number;
    name: string;
  } | null;
  task: {
    id: number;
    name: string;
  } | null;
  hours: string;
  comment: string;
  additionalText: string;
}
interface FormValues {
  split: Array<IFormData>;
}

interface ISplitDialog {
  showSplit: boolean;
  setShowSplit: Dispatch<SetStateAction<boolean>>;
  splitDialogData: IMoveHours & { additionalCommentLabel: string };
  projectOptions: IProjectOptions[];
}

export const SplitDialog = ({
  showSplit,
  setShowSplit,
  splitDialogData,
  projectOptions,
}: ISplitDialog) => {
  const { t } = useTranslation('moveHours');
  const siteLocale = useGetLocale();

  const { mutate: postRegistrationsSplit } = usePostRegistrationSplit();

  const {
    projectId,
    projectName,
    wbs,
    taskId,
    taskName,
    date,
    registeredHours,
    comment,
    additionalText,
    additionalCommentLabel,
    workItemGuid,
  } = splitDialogData;

  const formSchema = z.object({
    split: z.array(
      z.object({
        project: z.object({
          name: z.string().min(1),
          id: z.number(),
        }),
        task: z.object({
          name: z.string().min(1),
          id: z.number(),
        }),
        hours: z.string().min(1),
        comment: z.string().min(1),
        additionalText: z.string(),
      }),
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      split: [
        {
          project: {
            id: projectId,
            name: projectName,
          },
          task: {
            id: taskId,
            name: taskName,
          },
          hours: '',
          comment,
          additionalText,
        },
        {
          project: null,
          task: null,
          hours: '',
          comment: '',
          additionalText: '',
        },
      ],
    },
    resolver: zodResolver(formSchema),
  });

  const { fields } = useFieldArray({ name: 'split', control });

  const handleOk = (data: FormValues) => {
    const { split } = data;

    const formattedSplit = split.map((item) => ({
      targetTaskId: item.task?.id,
      hours: item.hours,
      comment: item.comment,
      additionalText: item?.additionalText,
    }));

    postRegistrationsSplit(
      { workItemGuid, postModel: formattedSplit },
      {
        onError: (error) => {
          toast.error(<ToastifyAlert description={String(error) as any} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onSuccess: () => {
          toast.success(<ToastifyAlert description={t('SplitSuccessToast')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
      },
    );

    setShowSplit(false);
  };

  return (
    <Dialog
      open={!!showSplit}
      onClose={() => setShowSplit(false)}
      aria-labelledby="dialog-time-registration"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-time-registration">{t('SplitTimeRegistrationText')}</DialogTitle>
      <DialogContent>
        <Stack gap={1.5}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderProjectName')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{projectName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderWbs')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{wbs}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderTaskName')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>{taskName}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TableHeaderDate')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>
                    {getDateStringFromSiteLocale(new Date(date), siteLocale)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{t('TotalHoursText')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={500}>
                    {formatTime(registeredHours, siteLocale)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Stack gap={1}>
            <Typography fontWeight={600} component="h3">
              {t('DistributeBetweenTasksText')}
            </Typography>
            <Grid container>
              {fields.map((field, index) => (
                <Grid item xs={6} key={field.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Stack gap={2}>
                        <FormControl fullWidth>
                          <Controller
                            name={`split.${index}.project`}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                size="small"
                                label={t('SelectProjectText')}
                                placeholder=""
                                options={projectOptions}
                                getOptionLabel={(option) =>
                                  typeof option === 'object' ? option.name : option
                                }
                                isOptionEqualToValue={(o, v) => o.id === v.id}
                                renderOption={(props, option, state) => (
                                  <li
                                    data-automation-id={`${option.name}Item`}
                                    {...props}
                                    key={`${option.name}-${state.index}`}
                                  >
                                    <HighlightMatchingText
                                      name={option.name}
                                      matchName={state.inputValue}
                                    />
                                  </li>
                                )}
                                onChange={(event, item) => {
                                  onChange(item);
                                  setValue(`split.${index}.task`, {
                                    id: 0,
                                    name: '',
                                  });
                                }}
                                error={!!errors.split?.[index]?.project}
                                value={value}
                                data-automation-id="SplitProjectNameDropdown"
                              />
                            )}
                            control={control}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <TaskAutoComplete
                            projectId={getValues(`split.${index}.project.id`)}
                            control={control}
                            index={index}
                            error={!!errors.split?.[index]?.task}
                          />
                        </FormControl>
                        <TextField
                          data-automation-id={`Hours${index}`}
                          label={t('HoursText')}
                          ariaLabel="hours-input"
                          variant="outlined"
                          size="small"
                          required
                          sx={{ width: '50%' }}
                          error={!!errors.split?.[index]?.hours}
                          {...register(`split.${index}.hours`)}
                        />
                        <TextField
                          data-automation-id={`Comment${index}`}
                          label={t('TableHeaderComment')}
                          ariaLabel="comment-input"
                          variant="outlined"
                          size="small"
                          error={!!errors.split?.[index]?.comment}
                          {...register(`split.${index}.comment`)}
                        />
                        <TextField
                          data-automation-id={`AdditionalText${index}`}
                          label={additionalCommentLabel}
                          ariaLabel="additional-input"
                          variant="outlined"
                          size="small"
                          multiline
                          {...register(`split.${index}.additionalText`)}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setShowSplit(false);
          }}
        >
          {t('CancelButton')}
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleOk)} data-automation-id="OkButton">
          {t('OkButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
