import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { toCamel } from 'src/utils/object';
import { getMoveHours } from 'src/mocks/moveHours';
import { IMoveHoursWrapper } from '../types';

const MOVE_HOURS_URL_PATH = '/api/v2/adjustments/move-hours';
export const MOVE_HOURS_MSW_STATUS_KEY = 'GetMoveHoursAPI';
export const MOVE_HOURS_KEY = 'MOVE_HOURS';

interface IParams {
  [key: string]: string;
}

const moveHoursFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<IMoveHoursWrapper> =>
  getFetch({
    path: `${MOVE_HOURS_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value: params[key],
      })),
    )}`.concat(filters),
    key: MOVE_HOURS_MSW_STATUS_KEY,
  });

export const useGetMoveHours = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: IParams,
) => {
  // Return {} if params is empty
  let filteredParams = {};
  if (params) {
    filteredParams = Object.entries(params).reduce<IParams>((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  const items = Object.keys(selectedFilterList);
  let filteredList = concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .flatMap((item) => [
        {
          param: `${item}Ids`,
          value: selectedFilterList[item].values.map((value) => value.value).toString(),
          isAppended: true,
        },
        {
          param: `Is${item}Inclusive`,
          value: String(selectedFilterList[item].isInclude),
          isAppended: true,
        },
      ]),
  );

  // Check if params is an empty object and replace the first '&' with '?'
  if (Object.keys(filteredParams).length === 0) {
    filteredList = filteredList.replace(/^&/, '?');
  }

  const { data, isError, isSuccess, ...restProps } = useQuery(
    [MOVE_HOURS_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await moveHoursFetch(filteredList, filteredParams || {});

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0,
    },
  );

  const summations = {
    id: 'TOTAL',
    projectsCount: data?.properties?.projectsCount,
    registeredHours: parseFloat(data?.properties?.totalRegisteredHours ?? '0'),
  };

  // For testing, to be remove
  if (isError) {
    const { entities, properties } = toCamel(getMoveHours) as any;
    return {
      data: entities?.map((item: any) => ({
        ...item.properties,
      })),
      summations,
      additionalCommentLabel: properties?.additionalCommentFieldName,
      isEmpty: (entities?.length ?? 0) <= 0,
      isError,
      isSuccess,
      ...restProps,
    };
  }

  return {
    data: data?.entities?.map((item) => ({
      ...item.properties,
    })),
    summations,
    additionalCommentLabel: data?.properties?.additionalCommentFieldName,
    isEmpty: (data?.entities?.length ?? 0) <= 0,
    isError,
    isSuccess,
    ...restProps,
  };
};
