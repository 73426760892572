export const filterMoveHoursFullResult = {
  Entities: [
    {
      Properties: {
        FilterCategory: 'Project',
        FilterItems: [
          {
            Id: 2,
            Name: 'Customer',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/customer/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 51,
            Name: 'ProjectStatus',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-status/content',
            ChildFilters: ['Project'],
          },

          {
            Id: 45,
            Name: 'ContractModel',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/contract-model/content',
          },
          {
            Id: 53,
            Name: 'ProjectDepartment',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-department/content',
            ChildFilters: ['Employee'],
          },

          {
            Id: 5,
            Name: 'ProjectManager',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-manager/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 6,
            Name: 'AccountManager',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/account-manager/content',
            ChildFilters: ['Project'],
          },

          {
            Id: 8,
            Name: 'ProjectType',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-type/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 9,
            Name: 'ProjectCategory',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-category/content',
          },
          {
            Id: 3,
            Name: 'Project',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 20,
            Name: 'Task',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/task/content',
            ParentFilters: ['Project', 'TaskStatus'],
          },
        ],
      },
    },
    {
      Properties: {
        FilterCategory: 'ResourceManagement',
        FilterItems: [
          {
            Id: 54,
            Name: 'EmployeeDepartment',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/employee-department/content',
            ChildFilters: ['Employee'],
          },
          {
            Id: 12,
            Name: 'Employee',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/employee/content',
            ParentFilter: [
              'ApprovalManager',
              'EmployeeType',
              'EmployeeDepartment',
              'LegalEntityEmployee',
            ],
          },
          {
            Id: 24,
            Name: 'EmployeeType',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/employee-type/content',
            ChildFilters: ['Employee'],
          },
        ],
      },
    },
    {
      Properties: {
        FilterCategory: 'General',
        FilterItems: [
          {
            Id: 62,
            Name: 'IsHideUnmovableRegistrations',
            Type: 'Checkbox',
            ContentUrl: 'api/v2/filters/is-hide-unmovable-registrations/content',
          },
          {
            Id: 63,
            Name: 'IsHideNonBillable',
            Type: 'Checkbox',
            ContentUrl: 'api/v2/filters/is-hide-non-billable/content',
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/DesignSevenMoveHours',
      Rel: 'self',
    },
  ],
};
