import { useEffect, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import { subDays } from 'date-fns';
import {
  IOptionsChangeParameters,
  TViewOptions,
} from 'src/components/layout/FilterPanelLayout/types';
import { MHViewOptionsStateKey } from './localStorageKeys';

import { Table } from './components/Table';
import { PeriodPicker } from '../ResourcePlanner/components/PeriodPicker';

const defaultStartDate = subDays(new Date(), 8);
const defaultEndDate = subDays(new Date(), 1);

export const MoveHours = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { savedView } = useGetSavedView(pageIdentifier);

  const [activeView, setActiveView] = useState<string>(savedView[0]?.filterViewId || '');

  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(undefined);

  const [initialDateStart, setInitialDateStart] = useState(defaultStartDate);
  const [initialDateEnd, setInitialDateEnd] = useState(defaultEndDate);

  useEffect(() => {
    const viewOptions = savedView.find((v: any) => v.filterViewId === activeView)?.viewOptions;
    setChangedViewOptions(viewOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    if (changedViewOptions?.['period-starts-at']) {
      setInitialDateStart(new Date(changedViewOptions['period-starts-at']));
    } else {
      setInitialDateStart(defaultStartDate);
    }
    if (changedViewOptions?.['period-ends-at']) {
      setInitialDateEnd(new Date(changedViewOptions['period-ends-at']));
    } else {
      setInitialDateEnd(defaultEndDate);
    }
    localStorage.setItem(MHViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };
  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        savedViewsList={savedView}
        changedViewOptions={changedViewOptions}
        setChangedViewOptions={setChangedViewOptions}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        <Table
          selectedViewOptions={changedViewOptions}
          onViewOptionsChange={onViewOptionsChange}
          filterSection={
            <FilterSection
              changedViewOptions={changedViewOptions}
              onViewOptionsChange={onViewOptionsChange}
              viewOptionsLeftFilter={
                <PeriodPicker
                  initialDateStart={initialDateStart}
                  initialDateEnd={initialDateEnd}
                  onChange={onViewOptionsChange}
                  preSelectOptions="pastPreSelectOptions"
                />
              }
            />
          }
        />
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
