export const getTaskOptions = {
  Entities: [
    {
      Properties: {
        Id: 10,
        Name: 'NPS time',
      },
    },
    {
      Properties: {
        Id: 11,
        Name: 'Workshops',
        Items: [
          {
            Id: '12',
            Name: '» Data Work',
          },
          {
            Id: '13',
            Name: '» Resource Work',
          },
        ],
      },
    },
    {
      Properties: {
        Id: 14,
        Name: 'Acadre integration',
        Items: [
          {
            Id: 15,
            Name: 'Preparation',
            Items: [
              {
                Id: 16,
                Name: '» On-line support',
              },
              {
                Id: 17,
                Name: '» Off-line support',
              },
            ],
          },
        ],
      },
    },
    {
      Properties: {
        Id: 18,
        Name: 'Internal meetings (O13001)',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/move-hours/projects/*/tasks',
      Rel: 'self',
    },
  ],
};
