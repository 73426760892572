export const getProjectOptions = {
  Entities: [
    {
      Properties: {
        Id: 1,
        Name: 'P01.001 _TIM - Time & Material',
      },
    },
    {
      Properties: {
        Id: 2,
        Name: 'P02.003 _TIM - New Reporting',
      },
    },
    {
      Properties: {
        Id: 3,
        Name: 'P05.006 _TIM - Implementing',
      },
    },
    {
      Properties: {
        Id: 4,
        Name: 'P13.222 _TIM - Debugging',
      },
    },
    {
      Properties: {
        Id: 5,
        Name: 'P16.665 _TIM - Scripting',
      },
    },
    {
      Properties: {
        Id: 6,
        Name: 'P20.444 _TIM - Administration',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/move-hours/projects',
      Rel: 'self',
    },
  ],
};
