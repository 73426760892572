import { ISavedView } from 'src/apis/savedViewAPI';

export const SNACKBAR_CONFIG = [
  {
    type: 'save',
    title: 'SaveViewSuccessToastTitle',
    description: 'SaveViewSuccessToastBody',
  },
  {
    type: 'edit',
    title: 'UpdateViewSuccessToastTitle',
    description: 'UpdateViewSuccessToastBody',
  },
  {
    type: 'delete',
    title: 'DeleteViewSuccessToastTitle',
    description: 'DeleteViewSuccessToastBody',
  },
  {
    type: 'changes',
    title: 'ChangesViewSuccessToastTitle',
    description: 'ChangesViewSuccessToastBody',
  },
  {
    type: 'duplicate',
    title: 'DuplicateViewSuccessToastTitle',
    description: 'DuplicateViewSuccessToastBody',
  },
];

export const INITIAL_SAVED_VIEW: ISavedView = {
  title: '',
  isDefault: false,
  createdDate: new Date(),
  updatedDate: new Date(),
  filters: {},
  viewOptions: {
    'search-text': '',
    DateRange: {
      PeriodStartsAt: new Date(),
      PeriodEndsAt: new Date(),
    },
  },
};

export const SEARCH_TEXT_FILTER = {
  name: 'SearchText',
  value: '',
  isInclusive: true,
  filterType: 'Textbox',
};

export const DATE_RANGE_FILTER = {
  name: 'DateRange',
  value: {
    PeriodStartsAt: '',
    PeriodEndsAt: '',
  },
  isInclusive: true,
  filterType: 'DateRange',
};

export const preDefinedFilters = ['MyActiveProjects', 'ActiveProjects', 'AllProjects'];
