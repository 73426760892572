import { MenuItem } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetTaskOptions } from 'src/apis/moveHoursAPI';
import { ITaskOptions } from 'src/apis/moveHoursAPI/types';
import { Autocomplete } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';

interface ITaskAutoComplete {
  projectId: number;
  control: Control<any>;
  index: number;
  error: boolean;
}

export const TaskAutoComplete = ({ projectId, control, index, error }: ITaskAutoComplete) => {
  const { t } = useTranslation('moveHours');
  const { taskOptions } = useGetTaskOptions(projectId);

  const flattenTaskOptions = (options: ITaskOptions[]) => {
    const flattened: { id: number; name: string; depth: number; isClickable: boolean }[] = [];

    const flatten = (opts: ITaskOptions[], depth = 1) => {
      opts.forEach((option) => {
        flattened.push({
          id: option.id,
          name: option.name,
          depth,
          isClickable: !option.items || option.items.length === 0,
        });
        if (option.items) {
          flatten(option.items, depth + 1);
        }
      });
    };

    flatten(options);
    return flattened;
  };

  return (
    <Controller
      name={`split.${index}.task`}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          size="small"
          label={t('SelectTaskText')}
          placeholder=""
          options={flattenTaskOptions(taskOptions) ?? []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderOption={(props, option, state) =>
            option.isClickable ? (
              <li
                {...props}
                data-automation-id={`${option.name}Item`}
                key={`${option.name}-${state.index}`}
                style={{ paddingLeft: `${option.depth}rem`, whiteSpace: 'normal' }}
              >
                <HighlightMatchingText name={option.name} matchName={state.inputValue} />
              </li>
            ) : (
              <MenuItem
                key={`${option.name}-${state.index}`}
                sx={{ pl: option.depth * 2, whiteSpace: 'normal' }}
                disabled
              >
                {option.name}
              </MenuItem>
            )
          }
          onChange={(event, item) => {
            onChange(item);
          }}
          error={error}
          value={value}
          data-automation-id="SplitTaskNameDropdown"
        />
      )}
      control={control}
    />
  );
};
