import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { MOVE_HOURS_KEY } from '../get/getMoveHoursAPI';

const MOVE_HOURS_URL_PATH = '/api/v2/move-hours';
export const PUT_MOVE_HOURS_MSW_STATUS_KEY = 'putMoveHoursAPI';

interface IUpdateModel {
  targetProjectId: number;
  targetTaskId: number;
}

const updateMoveHours = ({
  timeRegistrationIds,
  updateModel,
}: {
  timeRegistrationIds: string[];
  updateModel: IUpdateModel;
}) =>
  putFetch({
    path: `${MOVE_HOURS_URL_PATH}?timeRegistrationIds=${timeRegistrationIds
      .map((project) => project)
      .join(',')}`,
    key: PUT_MOVE_HOURS_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const useUpdateMoveHours = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMoveHours, {
    onSuccess: () => {
      queryClient.invalidateQueries([MOVE_HOURS_KEY]);
    },
  });
};
