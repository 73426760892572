import { useEffect, useState } from 'react';
import { DayRangePicker, TDateRange } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';

interface ResourceViewOptionsProps {
  onChange?: (item: ViewOptionsChangeParameters[]) => void;
  initialDateStart?: Date;
  initialDateEnd?: Date;
  preSelectOptions?: PreSelectOptionsKey;
}

type PreSelectOptionsKey = 'defaultPreSelectOption' | 'pastPreSelectOptions';
type PeriodType = 'week' | 'month' | 'year' | 'day';
type TFrom = 'this' | 'next' | 'last' | 'past';

export const PeriodPicker = ({
  onChange,
  initialDateStart,
  initialDateEnd,
  preSelectOptions = 'defaultPreSelectOption',
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation('dayPicker');

  // Date picker options
  const { currentLanguage } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const [dateRange, setDateRange] = useState<TDateRange | undefined>({
    from: initialDateStart,
    to: initialDateEnd,
  });
  const preSelectOptionsList = {
    defaultPreSelectOption: [
      {
        label: t('PreselectButtonOneWeek'),
        periodType: 'week' as PeriodType,
        periodAmount: 1,
      },
      {
        label: t('PreselectButtonOneMonth'),
        periodType: 'month' as PeriodType,
        periodAmount: 1,
      },
      {
        label: t('PreselectButtonThreeMonths'),
        periodType: 'month' as PeriodType,
        periodAmount: 3,
      },
      {
        label: t('PreselectButtonOneYear'),
        periodType: 'year' as PeriodType,
        periodAmount: 1,
      },
    ],
    pastPreSelectOptions: [
      {
        label: t('PreselectButtonLast7Days'),
        periodType: 'week' as PeriodType,
        periodAmount: 1,
        from: 'past' as TFrom,
      },
      {
        label: t('PreselectButtonLastMonth'),
        periodType: 'month' as PeriodType,
        periodAmount: 1,
        from: 'past' as TFrom,
      },
      {
        label: t('PreselectButtonLast3Months'),
        periodType: 'month' as PeriodType,
        periodAmount: 3,
        from: 'past' as TFrom,
      },
    ],
  };

  useEffect(() => {
    setDateRange({
      from: initialDateStart,
      to: initialDateEnd,
    });
  }, [initialDateStart, initialDateEnd]);

  const handleDateSelect = () => {
    if (dateRange?.from && dateRange?.to) {
      onChange?.([
        {
          name: 'period-starts-at',
          value: format(dateRange.from, 'yyyy-MM-dd'),
        },
        { name: 'period-ends-at', value: format(dateRange.to, 'yyyy-MM-dd') },
      ]);
    }
  };

  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  return (
    <DayRangePicker
      data-automation-id="ResourcePlannerPeriodPicker"
      mode="range"
      dateRange={dateRange}
      setDateRange={setDateRange}
      fromDate={preSelectOptions === 'pastPreSelectOptions' ? undefined : new Date()}
      weekStartsOn={1}
      showWeekNumber
      onApplyHandler={handleDateSelect}
      inputFromLabel={t('DateInputFromLabel')}
      inputToLabel={t('DateInputToLabel')}
      buttonApplyText={t('ApplyButtonText')}
      buttonCancelText={t('CancelButtonText')}
      dateFormatInvalidMessage={t('NotValidDateFormatMessage')}
      dateInvalidMessage={t('NotValidDate')}
      startDateIsOutOfRangeMessage={t('StartDateIsBeforeAllowedDateMessage')}
      endDateIsOutOfRangeMessage={t('EndDateIsBeforeAllowedDateMessage')}
      dateFormat={localeDateFormat(siteLanguage || 'da')}
      preselectOptions={preSelectOptionsList[preSelectOptions]}
      labels={{
        labelPrevious: (labelMonth) =>
          `${t('PreviousMonthButtonText')}${
            labelMonth
              ? `: ${labelMonth.toLocaleString(currentLanguage, {
                  month: 'long',
                })}`
              : ''
          }`,
        labelNext: (labelMonth) =>
          `${t('NextMonthButtonText')}${
            labelMonth
              ? `: ${labelMonth.toLocaleString(currentLanguage, {
                  month: 'long',
                })}`
              : ''
          }`,
        labelWeekNumber: (weekNumber) => `${t('PickEntireWeekButtonText')} ${weekNumber}`,
      }}
    />
  );
};
