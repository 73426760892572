import { FormControl, MenuItem, Button, Stack } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTaskOptions, useUpdateMoveHours } from 'src/apis/moveHoursAPI';
import { Autocomplete, ToastifyAlert } from 'src/components/mui-components';
import { toast } from 'react-toastify';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { IProjectOptions, ITaskOptions } from 'src/apis/moveHoursAPI/types';
import { ReportingToolbar } from 'src/components/global/ReportingTable';

interface IToolbarProps {
  selectedRowProject: string[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
  projectOptions?: IProjectOptions[];
}

export const Toolbar = ({
  selectedRowProject,
  setColumnsButtonEl,
  filterSection,
  projectOptions,
}: IToolbarProps) => {
  const { t } = useTranslation('moveHours');

  const [projectId, setProjectId] = useState<number>(0);
  const [taskId, setTaskId] = useState<number>(0);

  const { taskOptions } = useGetTaskOptions(projectId);
  const { mutate: updateMoveHours } = useUpdateMoveHours();

  const flattenTaskOptions = (options: ITaskOptions[]) => {
    const flattened: { id: number; name: string; depth: number; isClickable: boolean }[] = [];

    const flatten = (opts: ITaskOptions[], depth = 1) => {
      opts.forEach((option) => {
        flattened.push({
          id: option.id,
          name: option.name,
          depth,
          isClickable: !option.items || option.items.length === 0,
        });
        if (option.items) {
          flatten(option.items, depth + 1);
        }
      });
    };
    flatten(options);
    return flattened;
  };

  useEffect(() => {
    setProjectId(0);
    setTaskId(0);
  }, [selectedRowProject]);

  const handleProjectChange = (newValue: IProjectOptions) => {
    setProjectId(newValue.id);
  };

  const handleTaskChange = (newValue: ITaskOptions) => {
    setTaskId(newValue.id);
  };

  const onApply = async () => {
    await updateMoveHours(
      {
        timeRegistrationIds: selectedRowProject,
        updateModel: { targetProjectId: projectId, targetTaskId: taskId },
      },
      {
        onError: (error) => {
          toast.error(<ToastifyAlert description={String(error)} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onSuccess: () => {
          toast.success(
            <ToastifyAlert
              description={`${selectedRowProject.length} ${t('MoveHoursSuccessToast')}`}
            />,
            {
              autoClose: 5000,
              closeButton: false,
            },
          );
        },
      },
    );
  };

  return (
    <ReportingToolbar
      selectedRowProject={selectedRowProject}
      setColumnsButtonEl={setColumnsButtonEl}
      filterSection={filterSection}
      toolbarContent={
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Autocomplete
              size="small"
              label={t('SelectProjectText')}
              placeholder=""
              options={projectOptions ?? []}
              getOptionLabel={(option) => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(o, v) => o.name === v.name}
              componentsProps={{ popper: { style: { width: 200 } } }}
              renderOption={(props, option, state) => (
                <li
                  data-automation-id={`${option.name}Item`}
                  {...props}
                  key={`${option.name}-${state.index}`}
                >
                  <HighlightMatchingText name={option.name} matchName={state.inputValue} />
                </li>
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleProjectChange(newValue as IProjectOptions);
                }
              }}
              sx={{ backgroundColor: 'white' }}
              data-automation-id="ToolbarProjectNameDropdown"
            />
          </FormControl>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Autocomplete
              size="small"
              label={t('SelectTaskText')}
              placeholder=""
              options={flattenTaskOptions(taskOptions)}
              getOptionLabel={(option) => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(o, v) => o.name === v.name}
              componentsProps={{ popper: { style: { width: 200 } } }}
              disabled={!projectId || projectId === 0}
              renderOption={(props, option, state) =>
                option.isClickable ? (
                  <li
                    {...props}
                    data-automation-id={`${option.name}Item`}
                    key={`${option.name}-${state.index}`}
                    style={{ paddingLeft: `${option.depth}rem`, whiteSpace: 'normal' }}
                  >
                    <HighlightMatchingText name={option.name} matchName={state.inputValue} />
                  </li>
                ) : (
                  <MenuItem
                    key={`${option.name}-${state.index}`}
                    sx={{ pl: option.depth * 2, whiteSpace: 'normal' }}
                    disabled
                  >
                    {option.name}
                  </MenuItem>
                )
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  handleTaskChange(newValue as unknown as ITaskOptions);
                }
              }}
              sx={{ backgroundColor: !projectId || projectId === 0 ? 'initial' : 'white' }}
              data-automation-id="ToolbarTaskNameDropdown"
            />
          </FormControl>
          <Button variant="outlined" size="small" onClick={onApply}>
            {t('MoveHoursText')}
          </Button>
        </Stack>
      }
    />
  );
};
