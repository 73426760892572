/* eslint-disable no-console */
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Button, TabMenu, ToastifyAlert } from 'src/components/mui-components';
import { z } from 'zod';
import {
  ISavedViewForm,
  SnackbarMode,
  useDeleteSavedView,
  usePostSavedView,
  useUpdateSavedView,
  TSnackbarKeys,
  INewSavedView,
} from 'src/apis/savedViewAPI';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  preDefinedFilters,
  SEARCH_TEXT_FILTER,
  SNACKBAR_CONFIG,
} from 'src/screens/SearchProjects/constants';
import isEqual from 'lodash/isEqual';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useFilterStore } from 'src/stores/FilterStore';
import { IFilterProps, IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { translationAnyText } from 'src/utils/translation';
import { FilterValues, TFormDialogMode } from 'src/apis/savedViewAPI/savedViewAPI';
import { useMenu } from 'src/hooks/useMenu';
import { MoreVert } from '@mui/icons-material';
import { DATE_RANGE_FILTER } from 'src/screens/MoveHours/constants';
import { DeleteViewDialog } from './components/DeleteViewDialog';
import { SaveViewFormDialog } from './components/SaveViewFormDailog/SaveViewFormDialog';
import styles from './SaveViewContainer.module.scss';
import { TViewOptions } from '../../types';

interface IFilterList {
  savedViewsList: INewSavedView[];
  tabOnChange: (id: string) => void;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  changedViewOptions?: TViewOptions;
  constructFilterPayload: (payload: FilterValues[]) => Record<string, IFilterProps>;
}

export const SaveViewContainer = ({
  savedViewsList,
  tabOnChange,
  activeView,
  setActiveView,
  changedViewOptions,
  constructFilterPayload,
}: IFilterList) => {
  const { t } = useTranslation('savedView');
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { selectedFilterList } = useFilterStore();

  const { mutate: postSavedView } = usePostSavedView();
  const { mutate: updateSavedView } = useUpdateSavedView();
  const { mutate: deleteSavedView } = useDeleteSavedView();

  const [haveFilterChanges, setHaveFilterChanges] = useState(false);
  const [haveOptionChanges, setHaveOptionChanges] = useState(false);
  const [recentAction, setRecentAction] = useState<string>('');
  const { anchorEl, menuIsOpen, setAnchorEl } = useMenu();

  const [showViewFormDialog, setShowViewFormDialog] = useState<TFormDialogMode | ''>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const ICON_BUTTON_ID = 'view-button';
  const TAB_MENU_ID = 'view-menu';

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const displayToast = (type: SnackbarMode | 'error') => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};

    if (type === 'error') {
      toast.error(
        <ToastifyAlert
          description={t('UnknownErrorMessage')}
          data-automation-id="ToastSaveViewError"
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    } else {
      toast.success(
        <ToastifyAlert
          title={t(title as TSnackbarKeys)}
          description={t(description as TSnackbarKeys)}
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    }
  };

  const translatePreDefinedFilter = (filter: string) => {
    if (preDefinedFilters.includes(filter)) {
      return translationAnyText(t, `SavedView${filter}TabText`);
    }
    return filter;
  };

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      action: () => {
        const viewIndex = savedViewsList.find((v) => v.filterViewId === activeView);
        if (viewIndex) {
          form.setValue('title', translatePreDefinedFilter(viewIndex.name), {
            shouldDirty: true,
            shouldValidate: true,
          });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowViewFormDialog('edit');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      action: () => {
        setShowDeleteDialog(true);
      },
    },
  ];

  const selectedSaveViewData = savedViewsList.find((view) => view.filterViewId === activeView);

  useEffect(() => {
    if (!activeView && savedViewsList.length > 0) {
      setActiveView(savedViewsList[0]?.filterViewId || '');
    }
  }, [activeView, savedViewsList, setActiveView]);

  useEffect(() => {
    if (recentAction && savedViewsList.length > 0) {
      if (recentAction === 'save') {
        const latestView = savedViewsList?.reduce((latest, current) =>
          new Date(latest.lastModified) > new Date(current.lastModified) ? latest : current,
        );
        setActiveView(latestView.filterViewId ?? '');
      } else if (recentAction === 'delete') {
        setActiveView(savedViewsList.length > 0 ? savedViewsList[0].filterViewId ?? '' : '');
      }
    }
  }, [recentAction, savedViewsList, setActiveView]);

  const formatFilters = (filters: Record<string, IFilterProps>) =>
    Object.keys(filters).map((key) => {
      const filter = filters[key];
      return {
        name: filter.label,
        value: filter.values.map((item) => item.value).join(','),
        isInclusive: filter.isInclude ?? true,
        filterType: filter.type ?? 'MultiSelectTypeSearch',
      };
    });

  const formatViewOptionFilters = (filters: TViewOptions) =>
    Object.keys(filters).map((key) => {
      const value = filters[key as keyof TViewOptions];
      // Here to expand to other view options
      if (key === 'date-range') {
        return {
          ...DATE_RANGE_FILTER,
          value,
        };
      }
      if (key === 'search-text') {
        return {
          ...SEARCH_TEXT_FILTER,
          value,
        };
      }
      return {};
    });

  const onSaveSubmit = async (data: ISavedViewForm) => {
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    // const period = [
    //   "ONE_WEEK"
    // ]

    if (showViewFormDialog === 'save') {
      postSavedView(
        {
          pageIdentifier,
          postModel: {
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('save');
            setRecentAction('save');
          },
        },
      );
    } else if (showViewFormDialog === 'edit') {
      await updateSavedView(
        {
          pageIdentifier,
          updateModel: {
            filterViewId: activeView,
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('edit');
            setRecentAction('edit');
          },
        },
      );
    }
    form.reset();
    setShowViewFormDialog('');
  };

  const onSaveChangesSubmit = async () => {
    const viewIndex = savedViewsList.find((v) => v.filterViewId === activeView);
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    await updateSavedView(
      {
        pageIdentifier,
        updateModel: {
          filterViewId: activeView,
          name: viewIndex?.name || '',
          isDefault: viewIndex?.isDefault ?? false,
          filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
        },
      },
      {
        onError: () => {
          displayToast('error');
        },
        onSuccess: () => {
          displayToast('changes');
          setRecentAction('changes');
        },
      },
    );
    form.reset();
  };

  const onDeleteSubmit = async () => {
    await deleteSavedView(activeView, {
      onError: () => {
        displayToast('error');
      },
      onSuccess: () => {
        displayToast('delete');
        setRecentAction('delete');
      },
    });
    setShowDeleteDialog(false);
  };

  const handleMenuItemClick = ({ action }: Pick<IMenuItem, 'action'>) => {
    action();
    setAnchorEl(null);
  };

  const hasChanges = useMemo(
    () => haveFilterChanges || haveOptionChanges,
    [haveFilterChanges, haveOptionChanges],
  );

  // Compare changes for filters and view
  useEffect(() => {
    const selectedView = savedViewsList?.find((view) => view.filterViewId === activeView);
    // Compare changes for filters
    if (selectedFilterList !== undefined) {
      setHaveFilterChanges(
        !isEqual(constructFilterPayload(selectedView?.filterValues ?? []), selectedFilterList),
      );
    }
    console.log('haschanges selectedView?.filterValues', selectedView);
    console.log('haschanges selectedView?.filterValues', changedViewOptions);

    // Remove empty string from changedViewOptions
    const updatedChangedViewOptions = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(changedViewOptions ?? {}).filter(([key, value]) => value !== ''),
    );

    console.log('updatedChangedViewOptions', updatedChangedViewOptions);
    // Check if 'search-text' is an empty string and not found in viewOptions
    // if (
    //   optionItems.some((item) => item.name === 'search-text' && item.value === '') &&
    //   viewOptions['search-text'] === undefined
    // ) {
    //   // Remove 'search-text' from changedViewOptions and optionsToObject
    //   const updatedChangedViewOptions = { ...changedViewOptions };
    //   delete updatedChangedViewOptions['search-text'];
    //   delete optionsToObject['search-text'];

    //   setChangedViewOptions({
    //     ...updatedChangedViewOptions,
    //     ...optionsToObject,
    //   });
    // } else {
    //   setChangedViewOptions({
    //     ...changedViewOptions,
    //     ...optionsToObject,
    //   });
    // }

    // Compare changes for view options
    if (changedViewOptions) {
      setHaveOptionChanges(!isEqual(selectedView?.viewOptions, updatedChangedViewOptions));
    }
  }, [activeView, changedViewOptions, constructFilterPayload, savedViewsList, selectedFilterList]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
          {activeView && (
            <>
              <Tabs
                value={activeView}
                onChange={(_, v) => {
                  setRecentAction('');
                  tabOnChange(v);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {savedViewsList.map((v) => (
                  <Tab
                    component="a"
                    key={v.filterViewId}
                    value={v.filterViewId}
                    label={
                      <Stack alignItems="center" direction="row" className={styles.tabLabel}>
                        <Typography fontSize={12} fontWeight={500}>
                          {translatePreDefinedFilter(v.name)}
                        </Typography>
                        <IconButton
                          aria-controls={TAB_MENU_ID}
                          aria-expanded={!!menuIsOpen}
                          aria-haspopup="true"
                          aria-label="More options"
                          color="primary"
                          data-automation-id={`FilterContainer${v.name.replace(/ /g, '')}Tab`}
                          id={`ICON_BUTTON_ID-${v.filterViewId}`}
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          size="small"
                          className={`${styles.tabIconButton} ${
                            anchorEl && anchorEl.id === `ICON_BUTTON_ID-${v.filterViewId}`
                              ? styles.tabIconButtonVisible
                              : ''
                          }`}
                        >
                          <MoreVert fontSize="small" />
                        </IconButton>
                      </Stack>
                    }
                    sx={{ py: 0 }}
                    wrapped
                    aria-current={activeView === v.filterViewId}
                    onClick={() => {
                      if (activeView === v.filterViewId) {
                        setRecentAction('');
                        tabOnChange(activeView);
                      }
                    }}
                    className={styles.tab}
                  />
                ))}
              </Tabs>
              <TabMenu
                id={TAB_MENU_ID}
                anchorEl={anchorEl}
                open={menuIsOpen}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ 'aria-labelledby': ICON_BUTTON_ID }}
              >
                {menuItems.map((item) => (
                  <MenuItem key={item.id} onClick={() => handleMenuItemClick(item)}>
                    <Stack direction="row" alignItems="center">
                      <Typography fontSize={14}>{item.label}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </TabMenu>
            </>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            disabled={!hasChanges}
            onClick={() => onSaveChangesSubmit()}
            data-automation-id="SavedViewSaveViewButton"
          >
            {t('SaveText')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={!selectedSaveViewData}
            onClick={() => setShowViewFormDialog('save')}
            data-automation-id="SavedViewEditViewButton"
          >
            {t('SaveAsText')}
          </Button>
        </Stack>
      </Stack>

      {/* For form dialog (Save new and update view) */}
      {showViewFormDialog && (
        <SaveViewFormDialog
          showViewFormDialog={showViewFormDialog}
          setShowViewFormDialog={setShowViewFormDialog}
          onSubmit={onSaveSubmit}
          form={form}
        />
      )}

      {/* For view delete dialog */}
      {showDeleteDialog && (
        <DeleteViewDialog
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          onDelete={onDeleteSubmit}
        />
      )}
    </>
  );
};
