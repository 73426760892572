/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { toCamel } from 'src/utils/object';
import { getSavedViewResult } from 'src/mocks/moveHours/savedView';
import { format, parseISO } from 'date-fns';
import { FilterValues, ISavedViewResponse, ViewOptionsValues } from '../savedViewAPI';

const GET_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';

export const GET_SAVED_VIEW_MSW_STATUS_KEY = 'getSavedViewAPI';
export const GET_SAVED_VIEW_KEY = 'GET_SAVED_VIEW';

const viewOptionsFilterTypes = ['Textbox', 'DateRange'];

const toKebabCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const transformViewOptions = (viewOptions: any) =>
  viewOptions.reduce((acc: any, option: any) => {
    if (option.name === 'DateRange' && typeof option.value === 'object' && option.value !== null) {
      const { periodStartsAt, periodEndsAt } = option.value;
      if (periodStartsAt)
        acc[toKebabCase('periodStartsAt')] = format(parseISO(periodStartsAt), 'yyyy-MM-dd');
      if (periodEndsAt)
        acc[toKebabCase('periodEndsAt')] = format(parseISO(periodEndsAt), 'yyyy-MM-dd');
    } else {
      acc[toKebabCase(option.name)] = option.value;
    }
    return acc;
  }, {});

const getSavedView = (pageIdentifier: string): Promise<ISavedViewResponse> =>
  getFetch({
    path: `${GET_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: GET_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useGetSavedView = (pageIdentifier: string) => {
  const { data, isError, ...queryRest } = useQuery(
    [GET_SAVED_VIEW_KEY, pageIdentifier],
    () => getSavedView(pageIdentifier),
    {
      retry: 0,
    },
  );

  // Split into filterValues and viewOptions
  const filteredSaveView = data?.entities
    ? data.entities.map(({ properties }) => {
        const viewOptions: ViewOptionsValues[] = [];
        const filterValues = properties.filterValues
          ?.map((filterValue) => {
            if (viewOptionsFilterTypes.includes(filterValue.filterType)) {
              viewOptions.push(filterValue as ViewOptionsValues);
              return null;
            }
            return {
              ...filterValue,
            } as unknown as FilterValues;
          })
          .filter(Boolean);

        return {
          ...properties,
          filterValues,
          viewOptions,
        };
      })
    : [];

  // Convert key to label
  const savedView = filteredSaveView.map((view) => ({
    ...view,
    filterValues: view?.filterValues?.map((filterValue) => ({
      ...filterValue,
      value: filterValue?.value?.map((val: any) => {
        const { key, ...rest } = val;
        return {
          ...rest,
          label: key,
        } as unknown as FilterValues;
      }),
    })),
  }));

  // Convert DateRange
  const updatedViewOptionsList = savedView.map((view) => ({
    ...view,
    viewOptions: transformViewOptions(view.viewOptions),
    filterValues: view.filterValues.map((filterValue: any) => ({
      ...filterValue,
      value: filterValue.value || [],
    })),
  }));

  // For testing, TO BE REMOVE
  const { entities } = toCamel(getSavedViewResult) as any;

  const filteredSaveViewTest = entities
    ? entities.map(({ properties }: any) => {
        const viewOptions: ViewOptionsValues[] = [];
        const filterValues = properties.filterValues
          ?.map((filterValue: any) => {
            if (filterValue.filterType === 'Textbox') {
              viewOptions.push(filterValue as ViewOptionsValues);
              return null; // Remove from filterValues
            }
            return {
              ...filterValue,
            } as unknown as FilterValues;
          })
          .filter(Boolean);

        return {
          ...properties,
          filterValues,
          viewOptions,
        };
      })
    : [];

  const savedViewTest = filteredSaveViewTest.map((view: any) => ({
    ...view,
    filterValues: view?.filterValues?.map((filterValue: any) => ({
      ...filterValue,
      value: filterValue?.value?.map((val: any) => {
        const { key, ...rest } = val;
        return {
          ...rest,
          label: key,
        } as unknown as FilterValues;
      }),
    })),
  }));

  // Convert DateRange
  const updatedViewOptionsList2 = savedViewTest.map((view: any) => ({
    ...view,
    viewOptions: transformViewOptions(view.viewOptions),
    filterValues: view.filterValues.map((filterValue: any) => ({
      ...filterValue,
      value: filterValue.value || [],
    })),
  }));

  return {
    savedView: updatedViewOptionsList2,
    ...queryRest,
  };
  // END For testing, TO BE REMOVE

  return {
    savedView: updatedViewOptionsList,
    ...queryRest,
  };
};
